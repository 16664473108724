import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    user: null,
    loading:false
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    showLoading(state){
      state.loading = true 
    },
    hideLoading(state){
      state.loading = false 
    }
  }
})
