<template>
  <div id="app">
    <a-layout-header>
      <div class="top">
        <div id="app-title"><a-icon type="read" :style="{fontSize:'16px',marginRight:'8px',color:'red'}"/>{{appTitle}}</div>
        <div class="top-item" id="main-menu">
          <a-menu theme="dark" mode="horizontal" @click="onMenuClick">
            <a-menu-item v-for="item in menus" :key="item.page" > <a-icon :type="item.icon" />{{item.title}}</a-menu-item>
          </a-menu>
        </div>
        <div>
          欢迎您：{{user?user.name:''}} <a-button type="link" ghost icon="logout" @click="logout">退出系统</a-button>
        </div>
      </div>
      
    </a-layout-header>
    <a-layout-content id="content">
      <router-view/>
    </a-layout-content>
    <div class="loading" v-if="loading">
      <a-spin>
        <a-icon
          slot="indicator"
          type="loading"
          style="font-size: 80px; color: white"
          spin
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { mapMutations,mapState } from 'vuex'

const menus = [
  {
    page: '/',
    title: '课文详情',
    icon: 'home'
  },
  {
    page: '/bookList',
    title: '课文列表',
    icon: 'cloud'
  },
  {
    page: '/englishWord',
    title: '英语词汇',
    icon: 'cloud'
  },
  {
    page: '/englishList',
    title: '英语课文列表',
    icon: 'cloud'
  },
  {
    page: '/evalSubjects',
    title: '测评列表',
    icon: 'cloud'
  },
  {
    page: '/englishList2',
    title: '英语课文列表2',
    icon: 'cloud'
  },
  {
    page: '/englishWord2',
    title: '英语词汇2',
    icon: 'cloud'
  },
]


export default {
  name: 'app',
  data(){
    return {
      appTitle:'小学教育工具管理后台',
      menus
    }
  },
  computed:{
    ...mapState(['user',"loading"])
  },
  methods: {
    ...mapMutations(['setUser']),
    onMenuClick({key}){
      this.$router.push(key)
    },
    async logout(){
      await window.cloud.auth().signOut()
      this.setUser(null)
      this.$router.replace('/login')
    }
  }
}
</script>

<style>
#app {
  min-height:100vh;
  background:#ECECEC;
  position: relative;
}

.top {
  max-width: 1200px;
  margin:auto;
  display:flex;
  flex-direction:row;
  color: white;
}
.top-item{
  height:64px;
  line-height:64px;
}
#app-title {
  font-size:16px;
}
#main-menu {
  margin-top: 9px;
  margin-left: 16px;
  flex:1;
}
#content {
  flex:1;
  max-width: 1200px;
  margin:auto; 
  padding:16px;
}

#footer {
  width:100%;
  height:48px;
  line-height:48px;
  text-align: center;
  position:absolute;
  bottom:0;
}

.loading {
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

</style>
