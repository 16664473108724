<template>
    <div class="container">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card class="panel" :bordered="false">
            <a-row :gutter="10" style="margin-bottom: 10px">
              <a-col :span="10">
                <a-select v-model="grade" style="width: 120px" @change="refresh">
                  <a-select-option value="1">一年级</a-select-option>
                  <a-select-option value="2">二年级</a-select-option>
                  <a-select-option value="3">三年级</a-select-option>
                  <a-select-option value="4">四年级</a-select-option>
                  <a-select-option value="5">五年级</a-select-option>
                  <a-select-option value="6">六年级</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="10">
                <a-select v-model="term" style="width: 100px" @change="refresh">
                  <a-select-option value="0">上学期</a-select-option>
                  <a-select-option value="1">下学期</a-select-option>
                </a-select>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-select v-model="edition" style="width: 320px" @change="refresh">
                <a-select-option v-for="item in editions" :value="item._id" :key="item._id">{{item.name}}</a-select-option>
              </a-select>
              </a-col>
            </a-row>
            <div v-for="it in list"
                :key="it._id"
                class="lession-item"
                :class="{ active: it._id == item._id }"
                @click="onSelect(it)"
              >
                <div class="lesstion-title">Unit {{ it.unit }} {{ it.title }} ({{it.words?it.words.length:0}})</div>
              </div>
          </a-card>
        </a-col>
        <a-col :span="16">
          <a-card class="panel" v-if="item" :title="item.title">
            <div slot="extra" v-if="list.length > 0">
              <span>【{{item.words?item.words.filter(item=>item.name).length:0}}】</span>
            </div>
            <a-tabs :activeKey="activeKey" @change="changeTab">
              <a-tab-pane key="base" tab="基本信息">
                <a-form-model-item label="课文标题">
                  <a-input v-model="form.title" />
                </a-form-model-item>
                <a-form-model-item label="第几模块">
                  <a-input-number v-model="form.moudle" />
                </a-form-model-item>
                <a-form-model-item label="第几单元">
                  <a-input-number v-model="form.unit" />
                </a-form-model-item>
               
              <div class="center"><a-button type="primary" class="func-btn" @click="saveBase" >保存基本信息</a-button></div>
            </a-tab-pane>
              <a-tab-pane key="words" tab="单词">
                <a-textarea v-model="form.words" style="height:400px;width:100%;"/>
                <div class="center"><a-button type="primary" class="func-btn" @click="saveWords" >保存单词</a-button></div>
              </a-tab-pane>
              <a-tab-pane key="content" tab="课文内容">
                <a-textarea v-model="form.content" style="height:400px;width:100%;"/>
                <div class="center"><a-button type="primary" class="func-btn" @click="saveContent" >保存课文内容</a-button></div>
              </a-tab-pane>
            </a-tabs>
          </a-card>
        </a-col>
      </a-row>
      
    </div>
  </template>
  
  <script>
  import checkLogin from "@/lib/checkLogin";
  import {  mapMutations,mapState } from "vuex";
  // import dayjs from 'dayjs'
  
  const log = console.log 
  
  export default {
    data() {
      return {
        editions:[],
      edition:'',
        list: [],
        grade: '1',
        term: '0',
        loading: false,
        item:{},
        activeKey:'words',
        form:{
          content:'',
          words:''
        }
      };
    },
  
    async mounted() {
      await checkLogin({
        store: this.$store,
        router: this.$router,
        route: this.$route,
        cloud: this.$cloud,
      });
      this.grade = localStorage.getItem('grade')||"1"
      this.term = localStorage.getItem('term')||"0",
      this.edition = localStorage.getItem('edition') || 'A'
      this.refresh();
      this.loadEditions()
    },
    computed: {
      ...mapState(["user"])
    },
    methods: {
      ...mapMutations(['showLoading','hideLoading']),
      async loadEditions(){
      const c = this.$cloud.database().collection("bb-english-editions");
      const ret = await c.orderBy('seq','asc').get()
      log('loadEditions>>>',ret)
      this.editions = ret.data  
    },
      onSelect(item){
        log(item)
        this.item = item 
        this.form.words = item.words.filter(item=>item.name).map(item=>`${item.name}=${item.cname||''}`).join('\n')
        this.form.content = item.content 
        this.form.title = item.title 
        this.form.unit = item.unit 
        this.form.moudle = item.moudle
      },
      changeTab(key){
        this.activeKey = key 
      },
      async refresh() {
        this.loading = true;
        this.showLoading()
        console.log("get english words >>>>");
        const c = this.$cloud.database().collection("bb-englishs2");
        const where = { grade: this.grade-0, term: this.term-0,edition:this.edition };
        localStorage.setItem('grade',this.grade)
        localStorage.setItem('term',this.term)
        localStorage.setItem('edition',this.edition)

        const ret = await c.where(where).limit(100).orderBy("unit", "asc").get();
        log('refresh>>>',ret)
        const list = ret.data
        this.list = list.map(item=>{
          item.words = item.words && item.words.length>0?item.words:[{name:'',cname:''}]
          return item 
        });
        if(list.length>0){
          this.onSelect(list[0])
        }
        
        this.loading = false;
        this.hideLoading()
      },
      async saveBase(){
        log('saveWords>>>>')
        const item = this.item ;
        const { _id } = item;
        const {title,moudle,unit} = this.form  
        
        this.showLoading()
        const c = this.$cloud.database().collection("bb-englishs2");
        const data = {title,moudle:moudle-0,unit:unit-0}
        await c.where({ _id }).update(data);
        this.hideLoading()

        item.title = title
        item.unit = unit  
      },
      async saveContent(){
        log('saveContent>>>>')

        const item = this.item 
        const { _id } = item;
        const {content} = this.form  
        
        this.showLoading()
        const c = this.$cloud.database().collection("bb-englishs2");
        item.content = content 
        const ret = await c.where({ _id }).update({ content});
        log(ret)

        this.hideLoading()
      },
      async saveWords(){
        log('saveWords>>>>')

        const item = this.item 
        const { _id } = item;
        const {words} = this.form  
        
        const temps = words.split('\n')
        const list = []
        for(let i=0;i<temps.length;i++){
          const ts = temps[i].split('=')
          if(ts.length==1){
            list.push({name:ts[0]})
          }else if(ts.length==2){
            list.push({name:ts[0],cname:ts[1]})
          }
        }
        log({list})
        this.showLoading()
        const c = this.$cloud.database().collection("bb-englishs2");
      
        const ret = await c.where({ _id }).update({ words:list});
        log(ret)
        item.words = list 
        this.hideLoading()
      },
    }
  }
  </script>
  
  <style scoped>
  .panel {
    margin-top: 12px;
    height: 90vh;
    overflow: scroll;
    border-radius: 8px;
    box-shadow: 4px 6px 12px rgb(0 0 0 / 10%);
  }
 
  .lession-item {
    padding:10px 4px;
    line-height: 20px;
    font-size: 16px;
    border-bottom: 1px solid #eee;
  }
  

  .active {
    background-color: aqua;
    border-radius:10px;
  }
  
  .center {
  text-align:center;
}

 
  .lesstion-title {
    font-weight:bold;
  }
 
  </style>
  
  