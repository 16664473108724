<template>
  <div class="container">
    <a-row :gutter="16">
      <a-col :span="8">
        <a-card class="panel" :bordered="false">
          <a-row :gutter="10" style="margin-bottom: 10px">
            <a-col :span="10">
              <a-select v-model="grade" style="width: 120px" @change="refresh">
                <a-select-option value="1">一年级</a-select-option>
                <a-select-option value="2">二年级</a-select-option>
                <a-select-option value="3">三年级</a-select-option>
                <a-select-option value="4">四年级</a-select-option>
                <a-select-option value="5">五年级</a-select-option>
                <a-select-option value="6">六年级</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="10">
              <a-select v-model="term" style="width: 100px" @change="refresh">
                <a-select-option value="0">上学期</a-select-option>
                <a-select-option value="1">下学期</a-select-option>
              </a-select>
            </a-col>
          </a-row>

          <div v-for="u in units" :key="u">
            <div class="unit-bar">第{{u.unit}}单元</div>
             <div 
              v-for="it in u.list"
              :key="it._id"
              class="lession-item"
              :class="{ active: it._id == item._id }"
              @click="onSelect(it)"
            >
              <div class="lesstion-title">{{ it.lession_type }}{{ it.no }}. {{ it.name }} </div>
              <div>
                识字{{ it.reads.length }}个 写字{{ it.writes.length }}个  词语{{ it.terms.length }}个
                <span v-if="it.lession_type=='课文' ||it.lession_type=='识字' || item.lession_type =='语文园地'|| item.lession_type =='古诗'" class="lession-tip" :class="{lession_warn:!it.content || it.content.trim().length==0}">(课文{{it.content?it.content.length +'字':'无'}})</span>
              </div>
                
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="16">
        <a-card class="panel" :title="title">
          <div slot="extra" v-if="list.length > 0">
            <span>【识字表 {{ readTotal }}个 写字表{{ writeTotal }}个  词语表{{ termTotal }}个 <span v-if="item && item.content">(课文{{item.content?item.content.length +'字':'无'}})</span>】</span>
            
          </div>
          <div>
            <a-form-model
              ref="form"
              :model="form"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
            <a-tabs :activeKey="activeKey" @change="changeTab">
              <a-tab-pane key="base" tab="基本信息">
              <a-form-model-item label="名称">
                <a-input v-model="form.name" />
              </a-form-model-item>

              <a-form-model-item label="课文类型">
                <a-select v-model="form.lession_type">
                  <a-select-option value="课文">课文</a-select-option>
                  <a-select-option value="识字">识字</a-select-option>
                  <a-select-option value="拼音">拼音</a-select-option>
                  <a-select-option value="语文园地">语文园地</a-select-option>
                  <a-select-option value="古诗">古诗</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="第几单元">
                <a-input-number v-model="form.unit" />
              </a-form-model-item>
              <a-form-model-item label="第几课">
                <a-input-number v-model="form.no"  />
              </a-form-model-item>


              <div class="center"><a-button type="primary" class="func-btn" @click="saveBase" >保存基本信息</a-button></div>
            </a-tab-pane>
            <a-tab-pane key="word" tab="识字表/写字表/词语表" force-render>
              <a-form-model-item label="识字表">
                <a-textarea
                  style="font-size: 18px;"
                  v-model="form.reads"
                  :auto-size="{ minRows: 1, maxRows: 2 }"
                />
                <a-tag  class="word-tag" color="blue" v-for="t in form.reads.split(' ').filter(item=>item)" :key="t" @click="wordDetail(t)">
                  {{ t }}
                </a-tag> ({{form.reads.split(' ').filter(item=>item).length}}个)
              </a-form-model-item>

              <a-form-model-item label="写字表">
                <a-textarea
                  style="font-size: 18px;"
                  v-model="form.writes"
                  :auto-size="{ minRows: 1, maxRows: 2 }"
                />
                <a-tag class="word-tag" color="blue" v-for="t in form.writes.split(' ').filter(item=>item)" :key="t" @click="wordDetail(t)">
                  {{ t }}
                </a-tag>({{form.writes.split(' ').filter(item=>item).length}}个)
              </a-form-model-item>

              <a-form-model-item label="词语表">
                <a-textarea
                  style="font-size: 18px;"
                  v-model="form.terms"
                  :auto-size="{ minRows: 1, maxRows: 2 }"
                />
                <a-tag  class="word-tag" color="blue" v-for="t in form.terms.split(' ').filter(item=>item)" :key="t" @click="wordDetail(t)">
                  {{ t }}
                </a-tag> ({{form.terms.split(' ').filter(item=>item).length}}个)
              </a-form-model-item>
              <div class="center"><a-button type="primary" class="func-btn" @click="saveWords" >保存词语</a-button></div>
            </a-tab-pane>
              <a-tab-pane key="content" tab="课文内容">
                <a-textarea v-model="form.content" class="lession-content" style="height:500px;width:100%;"/>
                <div class="center"><a-button type="primary" class="func-btn" @click="saveContent" >保存课文内容</a-button></div>
              </a-tab-pane>
              <a-tab-pane key="video" tab="课文视频">
                <a-upload  :action="uploadThumbnail"  :customRequest="uploadVideo"  name="avatar"  list-type="picture-card" class="avatar-uploader"
                        :show-upload-list="false"  :before-upload="beforeUpload"  @change="handleChangeThumbnail">
                        <a-button> <a-icon type="upload" /> 上传视频 </a-button>
                </a-upload>
                <div v-if="form.video" >视频地址:<a target="_blank" :href="form.video">{{form.video}}</a></div>
                <div ><a-input v-model="form.video" /></div>
                <a-progress v-if="showProgress" :percent="percent" />
                <div class="center"><a-button type="primary" class="func-btn" @click="saveVideo" >保存视频</a-button></div>
            </a-tab-pane>
            
            <a-tab-pane v-if="word" key="word-detail" :tab="word">
               <json-viewer v-if="json" :value="json" :expand-depth="1" copyable />
                <div class="center"><a-button type="primary" class="func-btn">保存</a-button></div>
            </a-tab-pane>

          </a-tabs>
            </a-form-model>
          </div>
        </a-card>
      </a-col>
    </a-row>
    
  </div>
</template>

<script>
import checkLogin from "@/lib/checkLogin";
import {  mapMutations,mapState } from "vuex";
// import dayjs from 'dayjs'

const log = console.log 

const columns = [
  { dataIndex: "seq", title: "#" },
  { dataIndex: "name", title: "课程名称" },
];

const merge = (words,list)=>{
  for(let i=0;i<list.length;i++){
    const item = list[i]
    if(!words.includes(item)){
      words.push(item)
    }
  }
}

export default {
  data() {
    return {
      units:[],
      list: [],
      item:{},
      columns,
      grade: '1',
      term: '0',
      loading: false,

      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      form: {
        name:'',
        reads: "",
        writes:"",
        terms:"",
        unit: 0,
        no: "",
        lession_type: "",
        content:'',
        video:''
      },
      percent:0,
      showProgress:false,
      activeKey:'content',
      word:'',
      wordItem:null,
      json:''
    };
  },

  async mounted() {
    await checkLogin({
      store: this.$store,
      router: this.$router,
      route: this.$route,
      cloud: this.$cloud,
    });
    this.grade = localStorage.getItem('grade')||"1"
    this.term = localStorage.getItem('term')||"0"
    this.refresh();
  },
  computed: {
    ...mapState(["user"]),
    title() {
      let t = "词汇列表";
      if (this.list.length > 0) {
        t += `【${this.item.name}】`;
      }
      return t;
    },
    readTotal() {
      if (this.list.length > 0) {
        return this.item.reads.length;
      }
      return 0;
    },
    writeTotal() {
      if (this.list.length > 0) {
        return this.item.writes.length;
      }
      return 0;
    },
    termTotal() {
      if (this.list.length > 0) {
        return this.item.terms.length;
      }
      return 0;
    },
  },
  methods: {
    ...mapMutations(['showLoading','hideLoading']),
    changeTab(key){
      this.activeKey = key 
    },
    async refresh() {
      this.loading = true;
      this.showLoading()
      console.log("get docs>>>>");
      const c = this.$cloud.database().collection("bb-book-words");
      const where = { grade: this.grade-0, term: this.term-0 };
      localStorage.setItem('grade',this.grade)
      localStorage.setItem('term',this.term)

      const ret = await c.where(where).limit(100).orderBy("unit", "asc").orderBy("seq", "asc").get();
      log(ret)
      const list = ret.data
      this.list = list;
      const units = []
      for(let i=0;i<list.length;i++){
        const item = list[i]
        const {unit} = item 
        if(!units.includes(unit)){
          units.push(unit)
        }
      }
      this.units = units.map(unit=>{
        const l = list.filter(item=>item.unit==unit)
        return {
          unit,
          list:l
        }
      })
      this.onSelect(list[0])
      this.loading = false;
      this.hideLoading()
    },
    
    onSelect(item) {
      this.item = item 
     
      const form = {
        name:item.name,
        lession_type:item.lession_type,
        no:item.no,
        unit:item.unit,
        reads:item.reads.join?item.reads.join(' '):'',
        writes:item.writes.join?item.writes.join(' '):'',
        terms:item.terms.join?item.terms.join(' '):'',
        content:item.content,
        video:item.video
      }
      this.form = form 
    },
    async saveBase(){
      log('saveWords>>>>')
      const item = this.item ;
      const { _id } = item;
      const {name,lession_type,unit,no} = this.form  
      
      this.showLoading()
      const c = this.$cloud.database().collection("bb-book-words");
      const data = {name,lession_type,unit:unit+'',no:no-0}
      await c.where({ _id }).update(data);
      this.hideLoading()

      if(item.unit==unit){
        item.name = name 
        item.no = no 
        item.lession_type = lession_type
      }else{
        this.refresh()
      }
    },
    async saveContent(){
      log('saveContent>>>>')

      const item = this.item ;
      const { _id } = item;
      const {content} = this.form  
    
      this.showLoading()
      const c = this.$cloud.database().collection("bb-book-words");
     
      await c.where({ _id }).update({ content});
      item.content = content 

      this.hideLoading()
    },

    async saveVideo(){
      log('saveVideo>>>>')

      const item = this.item ;
      log({item})
      const { _id } = item;
      const {video} = this.form  
    
      this.showLoading()
      const c = this.$cloud.database().collection("bb-book-words");
     
      const ret = await c.where({ _id }).update({ video});
      log({ret})
      item.video = video 

      this.hideLoading()

    },
    async saveWords() {
      log('saveWords>>>>')
      const item = this.item ;
      const { _id } = item;
      const {reads,writes,terms} = this.form  
      console.log({ _id, reads, writes, terms });
      const r = reads.split(' ').filter(item=>item)
      const w = writes.trim().includes(' ')?writes.split(' ').filter(item=>item):writes.split('')
      const t = terms.split(' ').filter(item=>item)
      this.showLoading()
      const c = this.$cloud.database().collection("bb-book-words");
      const words = []
      merge(words,r)
      merge(words,w)
      merge(words,t)

      await c.where({ _id }).update({ reads:r, writes:w, terms:t,words});
      item.reads = r 
      item.writes = w 
      item.terms = t 

      this.hideLoading()
    },
    async wordDetail(word){
      this.word = word 
      this.activeKey = "word-detail"

      this.showLoading()
      const c = this.$cloud.database().collection("bb-words");
      const ret = await c.where({name:word}).get()
      log(ret)
      if(ret.data.length==1){
        this.wordItem = ret.data[0]
        this.json = ret.data[0]
      }else{
        this.wordItem = {}
        this.json = {}
      }
      this.hideLoading()
    },
    handleChangeThumbnail(info) {
      console.log('handleChangeThumbnail>>>>>')
      console.log(info)
    },
    beforeUpload(file) {
      log(file)
      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      // if (!isJpgOrPng) {
      //   this.$message.error('You can only upload JPG file!');
      // }
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isLt2M) {
      //   this.$message.error('Image must smaller than 2MB!');
      // }
      // return isJpgOrPng && isLt2M;
      return true
    },
    async uploadThumbnail(file){
        console.log(file)
    },
    async uploadVideo(e){
      console.log('uploadThumbnail>>>>')
      const file = e.file
      console.log(file)
      await this.upload(file)
    },
    async upload(file){
      this.showProgress = true
      // const cloudPath = 'video/' + dayjs().format('YYYYMM') + '/' + (new Date()).getTime() + '.mp4'
      const {grade,term} = this
      const {name,lession_type,no} = this.form 
      const cloudPath = `video/${grade}/${term}/${lession_type}${no}-${name}.mp4`
      const result = await this.$cloud.uploadFile({
        cloudPath,
        filePath: file,
        onUploadProgress: (progressEvent) =>{
          // console.log(progressEvent);
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log({percentCompleted})
          this.percent = percentCompleted
        }
      })
      this.showProgress = false 
      const {fileID} = result 
      const ret = await this.$cloud.getTempFileURL({fileList:[fileID]})
      const {code,fileList} = ret
      if(!code){
        log(fileList[0].tempFileURL)
        this.form.video = fileList[0].tempFileURL
        this.form = {...this.form}
        this.saveVideo()
      }
    },
  },
};
</script>

<style scoped>
.panel {
  margin-top: 12px;
  height: 90vh;
  overflow: scroll;
  border-radius: 8px;
  box-shadow: 4px 6px 12px rgb(0 0 0 / 10%);
}

.btn-item {
  margin-right: 16px;
}

.lession-item {
  height: 80px;
  line-height: 40px;
  font-size: 16px;
  border-bottom: 1px solid #eee;
}

.unit-bar {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  background-color:#eee;
  text-align:center;
  border-radius:10px;
}

.active {
  background-color: aqua;
  border-radius:10px;
}

.word-box {
  display: flex;
  flex-wrap: wrap;
}
.lesstion-title {
  font-weight:bold;
}
.word-item {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 10px;
}
.func-btn {
  margin-left: 10px;
}

.lession-content {
  font-size: 16px;
  line-height:2em;
}

.lession-tip {
  color:#666;
}

.lession_warn {
  color:red;
}

.center {
  text-align:center;
}

.word-tag {
  font-size:18px;
}
</style>

