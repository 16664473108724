<template>
  <div class="container">
    <a-card class="panel" :bordered="false">
      <a-row :gutter="10" style="margin-bottom: 10px">
        <a-col :span="3">
          <a-select v-model="grade" style="width: 120px" @change="refresh">
            <a-select-option value="1">一年级</a-select-option>
            <a-select-option value="2">二年级</a-select-option>
            <a-select-option value="3">三年级</a-select-option>
            <a-select-option value="4">四年级</a-select-option>
            <a-select-option value="5">五年级</a-select-option>
            <a-select-option value="6">六年级</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="2">
          <a-select v-model="term" style="width: 100px" @change="refresh">
            <a-select-option value="0">上学期</a-select-option>
            <a-select-option value="1">下学期</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="8" :offset="1">
          <a-select v-model="edition" style="width: 320px" @change="refresh">
            <a-select-option v-for="item in editions" :value="item._id" :key="item._id">{{item.name}}</a-select-option>
          </a-select>
        </a-col>
        <a-col :offset="6" :span="2">
          <a-button type="primary" class="func-btn" @click="save" >批量保存</a-button >
        </a-col>
      </a-row>
      
      <div v-for="(item, i) in list" :key="item._id" class="lession-item" :class="{ active: index == i }">
        <span class="input-tip">标题：</span>
        <a-input v-model="item.title" style="width: 240px" />
        <span class="input-tip">单元：</span>
        <a-input v-model="item.unit" style="width:120px"/>
        <span class="input-tip">模块：</span>
        <a-input v-model="item.moudle" style="width:60px;"/>
        <a-button type="primary" @click="add(i)">后面加一项</a-button>
        <a-button type="text" @click="remove(i)">删除</a-button>
      </div>
      <a-button type="primary" @click="reorder">重新调整顺序</a-button>
    </a-card>
   
  </div>
</template>

<script>
import checkLogin from "@/lib/checkLogin";
import { mapMutations, mapState } from "vuex";
const log = console.log

export default {
  data() {
    return {
      editions:[],
      edition:'',
      list: [],
      index: 0,
      grade: '1',
      term: '0',
      loading: false,
      ids:[]
    };
  },

  async mounted() {
    await checkLogin({
      store: this.$store,
      router: this.$router,
      route: this.$route,
      cloud: this.$cloud,
    });
    this.grade = localStorage.getItem('grade')||"1"
    this.term = localStorage.getItem('term')||"0"
    this.edition = localStorage.getItem('edition') || 'A'
    this.refresh();
    this.loadEditions()
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    async loadEditions(){
      const c = this.$cloud.database().collection("bb-english-editions");
      const ret = await c.orderBy('seq','asc').get()
      log('loadEditions>>>',ret)
      this.editions = ret.data  
    },
    async refresh() {
      this.loading = true;
      this.showLoading()
      console.log("get english docs>>>>");
      const c = this.$cloud.database().collection("bb-englishs2");
      localStorage.setItem('grade',this.grade)
      localStorage.setItem('term',this.term)
      localStorage.setItem('edition',this.edition)
      const where = { grade: this.grade - 0, term: this.term - 0 ,edition:this.edition};
      const ret = await c.where(where).limit(100).orderBy("unit", "asc").get();
      log(ret)
      const list = ret.data
      this.ids = list.map(item=>item._id)
      this.list = list.length==0?[{title:''}]:list;
      this.loading = false;
      this.hideLoading()
    },

    async save() {
      const list = this.list.map(item=>{
        const {_id,title,moudle,unit} = item 
        const info = {title,moudle,unit}
        if(_id){
          info._id = _id
        }
        
        return info 
      })
      log(list)
      this.showLoading()
      const c = this.$cloud.database().collection("bb-englishs2");

      const ps = list.filter(item=>item._id).map(item=>c.where({_id:item._id}).update({
          unit:item.unit-0,
          moudle:item.moudle-0,
          edition:item.edition,
          title:item.title
      }))
      log('update>>>',ps.length)

      list.filter(item=>!item._id).forEach(item=>{
        const {title,moudle,unit} = item 
        const data = {title,moudle:moudle-0,unit:unit-0,grade:this.grade-0,term:this.term-0,edition:this.edition}
        ps.push(c.add(data))
      })
      const oldIds = this.ids 
      const newIds = list.filter(item=>item._id).map(item=>item._id)
      const delIds = oldIds.filter(id=>!newIds.includes(id))
      const _ = this.$cloud.database().command
      if(delIds.length>0){
        ps.push(c.where({_id:_.in(delIds)}).remove())
      }
      
      const rets = await Promise.all(ps)
      log(rets)
      await this.refresh()
      this.hideLoading()
    },
    add(index){
      const list = this.list 
      const newList = [...list.slice(0,index+1),{title:'',no:index+1},...list.slice(index+1,list.length)]
      this.list = newList 
    },
    remove(index){
      const list = this.list 
      const newList = [...list.slice(0,index),...list.slice(index+1,list.length)]
      this.list = newList 
    },
    reorder(){
      const list = this.list 
      list.forEach((item,index)=>{
        item.no = index + 1
      })
      this.list = [...list]
    }
  },
};
</script>

<style scoped>
.panel {
  margin-top: 12px;
  height: 90vh;
  overflow: scroll;
  border-radius: 8px;
  box-shadow: 4px 6px 12px rgb(0 0 0 / 10%);
}

.lession-item {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  border-bottom: 1px solid #eee;
  padding-left: 16px;
}

.active {
  background-color: aqua;
}
</style>

