<template>
  <div class="container">
    <a-row>
      <a-col :span="20">
        <a-form-model layout="inline">
          <a-form-model-item label="姓名">
            <a-input v-model="name" placeholder="请输入姓名" allowClear @pressEnter="refresh" />
          </a-form-model-item>
          <a-form-model-item label="手机号码">
            <a-input v-model="mobile" placeholder="请输入手机号码" allowClear @pressEnter="refresh"  />
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="refresh">查询</a-button>
          </a-form-model-item>
          <a-form-model-item label="用户数">
            <h1>{{pagination.total}}</h1>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      
    </a-row>
    <a-table rowKey="_id" :pagination="pagination" @change="handleTableChange"  
        :columns="columns" :data-source="list" >
     </a-table>
  </div>
</template>

<script>
import {mapState } from 'vuex'
import checkLogin from '@/lib/checkLogin'

const columns=[
  {dataIndex:'name',title:'用户名'},
  {dataIndex:'role',title:'角色'},
  {dataIndex:'mobile',title:'手机号码'},
  {dataIndex:'cName',title:'班级'}
]
export default {
  data(){
    return {
      list:[],
      name:'',
      mobile:'',
     
      columns,
      pagination: {
        pageSize:10,
        current:1
      },
      loading: false,
    }
  },
  
  async mounted(){
    await checkLogin({
      store:this.$store,
      router:this.$router,
      route:this.$route,
      cloud:this.$cloud
    })
    this.refresh()
  },
  computed:{
    ...mapState(['classes'])
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.refresh()
    },
    
    async refresh(){
      console.log('refresh>>>>>>')
      
      this.loading = true
      const db = this.$cloud.database()
      const {current,pageSize} = this.pagination
      const params = {}
      const {name,mobile} = this
      
      if(mobile){
          params.mobile = mobile
      }
      if(name){
          params.name = name
      }

      let  q = db.collection('users')
      
      if(Object.keys(params).length>0){
        q = q.where(params)
      }
      q = q.limit(pageSize)
      if(current>1){
        q = q.skip((current-1) *pageSize)
      }
      const ret = await q.orderBy('create_time','desc').get()
      // console.log(ret)
      const retCount = await q.count()
      // console.log(retCount)
      this.pagination = {...this.pagination,total:retCount.total}
      this.list = ret.data.map(item=>{
        const cs = this.classes.filter(i=>i._id==item.c)
        if(cs && cs.length==1){
          item.cName = cs[0].name
        }
        return item 
      })
      this.loading = false
    }
  }
}
</script>

<style scoped>
.container {
  background-color:white;
  padding: 16px;

  border-radius: 8px;
  box-shadow: 4px 6px 12px rgb(0 0 0 / 10%);
}

.tools {
  padding:16px;
}

.thumbnail-image {
  width:60px;
  height:60px;
}
</style>