import Vue from 'vue'

import cloudbase from "@cloudbase/js-sdk"

window._tcbEnv = window._tcbEnv || {};

export const envId = window._tcbEnv.TCB_ENV_ID;
export const region = window._tcbEnv.TCB_REGION;

const cloud = cloudbase.init({
  env: envId,
  region: region
});

// cloud.auth({ persistence: "local"})

Vue.prototype.$cloudbase = cloudbase
Vue.prototype.$cloud = cloud
window.cloud = cloud