<template>
    <div class="container">
      <div class="title">{{title}}</div>
      <div class="item" v-for="item in list" :key="item._id">
        <div class="title"> 
            {{item.seq}}.{{item.subject[0]}}
            <a-button type="primary"  @click="save(item)">保存</a-button>
        </div>
        <div class="option" v-for="(o,idx) in item.options" :key="o._id">
            {{letters[idx]}}. {{o.option}} [{{o.score}}分]
            <a-input-number v-model="o.score" class="num-input"/>

        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {mapState } from 'vuex'
  import checkLogin from '@/lib/checkLogin'
  const log = console.log 

  export default {
    data(){
      return {
        title:'',
        list:[],
        letters:['A','B','C','D','E','F','G']
      }
    },
    
    async mounted(){
      await checkLogin({
        store:this.$store,
        router:this.$router,
        route:this.$route,
        cloud:this.$cloud
      })
      this.refresh()
    },
    computed:{
      ...mapState(['classes'])
    },
    methods: {
     
      async refresh(){
        console.log('refresh>>>>>>')
        log(this.$route.query)
        const { evalId,name } = this.$route.query
        this.title = name 
        const db = this.$cloud.database()
       
        let  q = db.collection('evaluations').where({evalId})
       
        const ret = await q.orderBy('seq','asc').get()
       
        this.list = ret.data
      },
      async save(item){
        const {_id,options} = item 
        const db = this.$cloud.database()
        const ret = await  db.collection('evaluations').where({_id}).update({
            data:{
                options
            }
        })
        log(ret)
        this.$success({
          title: '温馨提示',
          content: '保存成功',
        })
      }
    }
  }
  </script>
  
  <style scoped>
  .container {
    background-color:white;
    padding: 16px;
  
    border-radius: 8px;
    box-shadow: 4px 6px 12px rgb(0 0 0 / 10%);
  }
  
  .title {
    font-size:16px;
    line-height:36px;
  }

  .option {
    font-size:14px;
    line-height:24px;
  }
  </style>