import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../Login.vue'
import Layout from '../Layout.vue'

import Home from '../views/Home.vue'
import User from '../views/User.vue'
import BookList from '../views/BookList.vue'
import TermFeeback from '../views/TermFeeback.vue'
import TermFeeback2 from '../views/TermFeeback2.vue'

import EnglishList from '../views/EnglishList.vue'
import EnglishWord from '../views/EnglishWord.vue'
import EnglishList2 from '../views/EnglishList2.vue'
import EnglishWord2 from '../views/EnglishWord2.vue'
import EvalSubjects from '../views/EvalSubjects.vue'
import EvalOptions from '../views/EvalOptions.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path:'/',
    component:Layout,
    children:[
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/user',
        name: 'User',
        component: User
      },
      {
        path: '/bookList',
        name: 'BookList',
        component: BookList
      },
      {
        path: '/termFeeback',
        name: 'TermFeeback',
        component: TermFeeback
      },
      {
        path: '/termFeeback2',
        name: 'TermFeeback2',
        component: TermFeeback2
      },
      
      {
        path: '/englishList',
        name: 'EnglishList',
        component: EnglishList
      },
      {
        path: '/englishWord',
        name: 'EnglishWord',
        component: EnglishWord
      },
      {
        path: '/englishList2',
        name: 'EnglishList2',
        component: EnglishList2
      },
      {
        path: '/englishWord2',
        name: 'EnglishWord2',
        component: EnglishWord2
      },
      {
        path: '/evalSubjects',
        name: 'EvalSubjects',
        component: EvalSubjects
      },
      {
        path: '/evalOptions',
        name: 'EvalOptions',
        component: EvalOptions
      }
      
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const auth = window.cloud.auth()
  const user = auth.currentUser
  if (to.name !== 'Login' && !user) next({ name: 'Login' })
  else next()
})

export default router
