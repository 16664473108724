<template>
    <a-card class="panel" :bordered="false">
          <a-row :gutter="10" style="margin-bottom: 10px">
            
            <a-col :offset="6" :span="2">
                <a-button type="primary" @click="onSubmit">保存</a-button>
            </a-col>
          </a-row>

          <div class="terms">
            <a-textarea
                  style="font-size: 16px"
                  v-model="form"
                  :auto-size="{ minRows: 5, maxRows: 10 }"
                />
        </div>
        </a-card>

</template>

<script>
import checkLogin from "@/lib/checkLogin";
import {  mapMutations } from "vuex";
const log = console.log 

export default {
    data(){
        return {
            form:''
        }
    },
    async mounted() {
        await checkLogin({
        store: this.$store,
        router: this.$router,
        route: this.$route,
        cloud: this.$cloud,
        });

        this.refresh();
    },
    methods:{
        ...mapMutations(['showLoading','hideLoading']),
        
        async onSubmit(){
           
            this.showLoading()
            const c = this.$cloud.database().collection('bb-user-words')
            const retQ = await c.get()
            if(retQ.data.length>0){
                const newTerms = this.form.split(' ').filter(item=>item)
                const ret = await c.where({_id:retQ.data[0]._id}).update({terms2:newTerms})
                log(ret)
            }
            
            this.hideLoading()
        },
        async refresh(){
            this.showLoading()
            console.log('refresh 222>>>>')
           
            const c = this.$cloud.database().collection('bb-user-words')
            const retQ = await c.get()
            this.form = retQ.data[0].terms2.join(' ')

            
            this.hideLoading()
        }
    }
}
</script>

<style scoped>
.terms{
            display: flex;
            flex-wrap: wrap;
}
.pinyin{
    width: 140px;
    text-align: center;
    margin: 10px;
}
.chinese{
    width: 140px;
    text-align: center;
    margin: 0 10px 0 10px;
    display: flex;
}
.name{
    flex:1;
}
</style>