
const checkLogin = async ({ router, route, cloud }) => {
    console.log('【checkLogin】')
    const user = cloud.auth().currentUser
    if (!user) {
        const path = route.path
        console.log({ path })
        if (path != '/login') {
            router.replace('/login')
            return
        }
    }
}

export default checkLogin