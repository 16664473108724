<template>
  <div id="container">
    <div class="login-box">
      <div class="login-panel">
        <div class="title">微信扫码登录</div>
        <div class="sub-title">扫「中典」小程序完成登录</div>
        <div class="qrcode-box">
          <img v-if="url" class="qrcode" :src="url" />
        </div>

        <div class="center">请使用微信【扫一扫】</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
const qrcode_url = 'https://rtc.libatech.ltd/qrcode?appId=wxdcc1337a84c5cd63&page=pages/my/login&scene='
const log = console.log 

export default {
  data() {
    return {
      url:''
    };
  },
  mounted() {
    log('mounted>>>>')
    this.showWxLogin();
  },
  methods: {
    ...mapMutations(['setUser']),
    async loginByTicket({ticket,phone}){
      console.log('loginByTicket>>>>>')
      const cloud = this.$cloud;
      this.loading = true 
      const loginState = await cloud.auth().customAuthProvider().signIn(ticket);
      console.log(loginState)

      this.loading = false
      
      console.log(loginState)
      const {user:{uid}} = loginState
      console.log({uid,phone})

      const ret = await cloud.database().collection('admins').where({_id:'uids'}).get()
      const phones = ret.data[0].phones
      if(phones.includes(phone)){
        this.setUser({name:phone})
        this.$router.push({ path: '/' })
      }else{
        this.$error({
          title: '温馨提示',
          content: '您没有权限进入管理后台！',
        });
        this.showWxLogin()
      }
    },
    async showWxLogin() {
      log('showWxLogin>>>>')
      const cloud = this.$cloudbase;
      
      this.loading = true 
      const c = cloud.database().collection("xcx-sessions");
      const ret = await c.add({created_time:(new Date())})
      log({ret})
      this.loading = false 
      const { id } = ret;
      const url = qrcode_url + id 
      log({ url, id });
      this.url = url;

      const watcher = c.where({ _id: id }).watch({
        onChange: async (snapshot) => {
          log(">>>>>  on change....");
          const doc = snapshot.docs[0];
          const { phone, ticket } = doc;
          log({ phone, ticket });
          if (phone) {
            watcher.close();
            if (ticket) {
               this.loginByTicket({ticket,phone})
            } 
          }
        },
        onError: (err) => {
          console.error("the watch closed because of error", err);
        },
      });
    }
  }
};
</script>

<style scoped>
#container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #ececec;
}
.login-box {
  width: 600px;
  height: 480px;
  background: #fff;
  text-align: left;
  display: flex;
  font-size: 18px;
}

.login-panel {
  padding: 32px 30px 0;
  flex: 1;
  position: relative;
}

.title {
  color: #067bef;
  font-size: 24px;
  font-weight: 500;
}

.sub-title {
  margin: 10px 0 40px 0;
}

.qrcode-box {
  height: 250px;
  text-align: center;
}
.qrcode {
  border: 1px solid #eee;
  border-radius: 120px;
  width: 240px;
  height: 240px;
}

.center {
  text-align: center;
}


</style>
