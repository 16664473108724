<template>
    <div class="container">
      <div class="item" v-for="(item,index) in list" :key="item._id" @click="detail(item)">
        {{index+1}}.{{item.name}}
      </div>
    </div>
  </template>
  
  <script>
  import {mapState } from 'vuex'
  import checkLogin from '@/lib/checkLogin'
  

  export default {
    data(){
      return {
        list:[],
       
      }
    },
    
    async mounted(){
      await checkLogin({
        store:this.$store,
        router:this.$router,
        route:this.$route,
        cloud:this.$cloud
      })
      this.refresh()
    },
    computed:{
      ...mapState(['classes'])
    },
    methods: {
     
      async refresh(){
        console.log('refresh>>>>>>')
        
       const db = this.$cloud.database()
       
        let  q = db.collection('eval-subjects')
        
        const ret = await q.orderBy('_id','asc').get()
        // console.log(ret)
        
        this.list = ret.data
      },
      detail(item){
        this.$router.push(`/evalOptions?evalId=${item._id}&name=${item.name}`)
      }
    }
  }
  </script>
  
  <style scoped>
  .container {
    background-color:white;
    padding: 16px;
  
    border-radius: 8px;
    box-shadow: 4px 6px 12px rgb(0 0 0 / 10%);
  }
  
  .item {
    line-height:40px;
  }
  </style>