<template>
    <a-card class="panel" :bordered="false">
          <a-row :gutter="10" style="margin-bottom: 10px">
            <a-col :span="4">
              <a-select v-model="grade" style="width: 120px" @change="refresh">
                <a-select-option value="1">一年级</a-select-option>
                <a-select-option value="2">二年级</a-select-option>
                <a-select-option value="3">三年级</a-select-option>
                <a-select-option value="4">四年级</a-select-option>
                <a-select-option value="5">五年级</a-select-option>
                <a-select-option value="6">六年级</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4">
              <a-select v-model="term" style="width: 100px" @change="refresh">
                <a-select-option value="0">上学期</a-select-option>
                <a-select-option value="1">下学期</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="6">
                {{ pageNo }}/{{ pages }}[{{this.list.filter(item=>item.checked).length}}]
              <a-button v-for="p in pages" type="primary" :key="p" @click="onChange(p)">{{ p }}</a-button>
            </a-col>
            <a-col :offset="6" :span="2">
                <a-button type="primary" @click="onSubmit">保存</a-button>
            </a-col>
          </a-row>

          <div class="terms">
            <div class="term" v-for="item in list" :key="item.name">
                <div class="pinyin">{{ item.pinyin[0] }}</div>
                <div class="chinese">
                    <div class="name">
                        <a-checkbox :checked="item.checked" @change="(e)=>onCheckBoxChange(item,e)"></a-checkbox>
                        {{item.name}}
                    </div>
                </div>
            </div>     
        </div>
        </a-card>

</template>

<script>
import checkLogin from "@/lib/checkLogin";
import {  mapMutations } from "vuex";
const log = console.log 

export default {
    data(){
        return {
            grade: '2',
            term: '0',
            list:[],
            pageNo:1,
            pageSize:60,
            pages:1
        }
    },
    async mounted() {
        await checkLogin({
        store: this.$store,
        router: this.$router,
        route: this.$route,
        cloud: this.$cloud,
        });

        this.refresh();
    },
    methods:{
        ...mapMutations(['showLoading','hideLoading']),
        onCheckBoxChange(item,e){
            console.log('on chanage>>>',item,e)
            item.checked = e.target.checked
            this.list = [...this.list]
        },
        async getMyWords(){
            const c = this.$cloud.database().collection('bb-user-words')
            const retQ = await c.get()
            if(retQ.data.length>0){
                const {terms} = retQ.data[0]
                return terms 
            }
            return []
        },
        async getAllWords(){
            return this.list.map(item=>item.name)
        },
        async onSubmit(){
            const selected = this.list.filter(item=>item.checked).map(item=>item.name)

            console.log(selected)
            this.showLoading()
            const c = this.$cloud.database().collection('bb-user-words')
            const retQ = await c.get()
            if(retQ.data.length>0){
                const {_id,terms} = retQ.data[0]
                const words = await this.getAllWords()
                const newTerms = []
                for(let i=0;i<terms.length;i++){
                    if(!words.includes(terms[i])){
                        newTerms.push(terms[i])
                    }
                }

                for(let i=0;i<selected.length;i++){
                    if(!newTerms.includes(selected[i])){
                        newTerms.push(selected[i])
                    }
                }
                const ret = await c.where({_id}).update({terms:newTerms})
                log(ret)
            }else{
                const ret = await c.add({terms:selected})
                log(ret)
            }
            
            this.hideLoading()
        },
        async refresh(){
            this.showLoading()
            console.log('refresh>>>>')
            const grade = this.grade
            const term = this.term
            const pageNo = this.pageNo
            const pageSize = this.pageSize

            const q = {grade:grade-0,term:term-0}
            // console.log(q)
            const terms = await this.getGradeTerms(q)
            const pages = Math.ceil(terms.length/pageSize)
            this.pages = pages 
            const list = (await this.main({pageNo,pageSize,terms}) ).filter(item=>item.pinyin)
            // console.log(list)
            const words = await this.getMyWords()
            log({words})
            this.list = list.map(item=>{
                // log(item.name)
                if(words.includes(item.name)){
                    item.checked = true
                }
                return item 
            })
            // log(this.list)
            this.hideLoading()
        },
        async main({pageNo,pageSize,terms}){
            const db = this.$cloud.database()
            const _ = db.command
            const ret = await db.collection('bb-words').aggregate()
            .match({name:_.in(terms)})
            .project({
                _id:0,
                name:1,
                pinyin:1
            })
            .sort({
                name:1
            }).skip((pageNo-1)*pageSize).limit(pageSize).end()
            return ret.data
        },
        async getGradeTerms(q){
            console.log('getGradeTerms>>>>')
            const {grade,term} = q
            const db = this.$cloud.database()
            const match = {grade}
            if(term==0 ||term==1){
                match.term = term
            }
            const ret = await db.collection("bb-book-words").aggregate()
                .match(match)
                .project({
                    terms:1
                })
                .limit(1000)
                .unwind('$terms')
                .end()
            return ret.data.map(item=>item.terms)
        },
        onChange(page){
            this.pageNo = page 
            this.refresh()
        }
    }
}
</script>

<style scoped>
.terms{
            display: flex;
            flex-wrap: wrap;
}
.pinyin{
    width: 140px;
    text-align: center;
    margin: 10px;
}
.chinese{
    width: 140px;
    text-align: center;
    margin: 0 10px 0 10px;
    display: flex;
}
.name{
    flex:1;
}
</style>